<template>
  <div>
    <h2>{{ $t("accessDc.titleWhereAndWhat") }}</h2>
    <p class="subTitle" v-if="false">
      {{ $t("accessDc.subTitleWhereAndWhat") }}
    </p>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--5-col">
        <p class="labelFour marginTop20">{{ $t("accessDc.apparati") }}</p>
      </div>
      <div class="mdl-cell mdl-cell--5-col">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onNo: this.form.apparati === 'no' }">
              {{ $t("shipping.no") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="sizeScreen">
            <label class="switch" @change="changeSwitchswitchApparati()">
              <input type="checkbox" class="changeApparatiOne" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="!sizeScreen">
            <label class="switch" @change="changeSwitchswitchApparati()">
              <input type="checkbox" class="changeApparatiTwo" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onYes: this.form.apparati === 'si' }">
              {{ $t("shipping.yes") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mdl-grid" v-if="viewWarehouse">
      <div class="mdl-cell mdl-cell--5-col">
        <p class="labelFour marginTop20">{{ $t("accessDc.warehouse") }}</p>
      </div>
      <div class="mdl-cell mdl-cell--5-col">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onNo: this.form.warehouse === 'no' }">
              {{ $t("accessDc.warehouseNo") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="sizeScreen">
            <label class="switch" @change="changeSwitchswitchWarehouse()">
              <input type="checkbox" class="changeWarehouseOne" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="!sizeScreen">
            <label class="switch" @change="changeSwitchswitchWarehouse()">
              <input type="checkbox" class="changeWarehouseTwo" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onYes: this.form.warehouse === 'si' }">
              {{ $t("accessDc.warehouseYes") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mdl-grid"
      v-if="trakaRooms.length > 0">
      <div class="mdl-cell mdl-cell--5-col">
        <p class="labelFour marginTop20">{{ $t("accessDc.keys") }}</p>
      </div>
      <div class="mdl-cell mdl-cell--5-col">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onNo: !this.switchKeys }">
              {{ $t("shipping.no") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="sizeScreen">
            <label class="switch" @change="changeSwitchswitchKeys()">
              <input type="checkbox" class="changeKeysOne" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell--1-col-tablet mdl-cell--1-col-phone" v-if="!sizeScreen">
            <label class="switch" @change="changeSwitchswitchKeys()">
              <input type="checkbox" class="changeKeysOne" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone">
            <p class="labelSwitch" :class="{ onYes: this.switchKeys }">
              {{ $t("shipping.yes") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mdl-grid" v-if="this.switchKeys">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour">{{ $t("accessDc.selectRoomsKeys") }}*</p>
        <span class="error" v-if="this.errosKeys">
          {{ $t("accessDc.selectRoomsKeysRequired") }}
        </span>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <div class="contentRoomsKeys">
          <div v-for="(room, index) in trakaRooms" :key="index" class="singleRoomKeys" @click="setRoomKey(room)"
            :id="['key-' + room.id]">
            {{ $t("accessDc.room") }} {{ room.name }}
            <div class="material-icons">done</div>
          </div>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <br />
        <span class="labelFour">{{ $t("accessDc.noteRoomsKeys") }}*</span><br />
        <span class="error" v-if="this.v$.form.dc.noteKey.$invalid && this.v$.form.dc.noteKey.$dirty">
          {{ $t("accessDc.noteRoomsKeys") }}
          {{ $t("checkCoverage.isRequired") }}
        </span>
        <div class="cdl-textarea">
          <textarea maxlength="200" name="note" id="textarea" v-model="form.dc.noteKey"></textarea>
        </div>
      </div>
    </div>

    <hr />

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour">{{ $t("accessDc.roomsAndRacks") }}*</p>
        <span class="error" v-if="this.errorRacks">
          {{ $t("accessDc.roomsAndRacks") }}
          {{ $t("checkCoverage.isRequired") }}
        </span>
      </div>
    </div>

    <!-- -->
    <div id="Users" style="padding-bottom: 0">
      <MyLoading :viewLoader="viewLoaderRacks" type="" />
      <div class="mdl-tabs mdl-js-tabs mdl-js-ripple-effect" v-if="viewLoaderRacks">
        <div class="mdl-tabs__tab-bar">
          <span class="mdl-tabs__tab" v-for="(room, index) in rooms" :key="index" :id="['room-' + room.id]"
            :class="{ 'is-active': index == 0 }" @click="setTab(room.id)">
            <span class="mdl-badge" :id="['badge-' + room.id]" data-badge="0">{{
      room.name
    }}</span>
            <div class="line"></div>
          </span>
        </div>

        <div class="mdl-tabs__panel" v-for="(room, index) in rooms" :key="index" :class="{ 'is-active': index == 0 }"
          :id="['panel-' + room.id]">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--6-col"></div>
            <div class=" mdl-cell mdl-cell--3-col mdl-cell--12-col-phone" :class="[IsMobile ? '' : 'mdl-cell--3-offset']">
              <button class="cdlButton" @click="selectAllRacks(room)" :id="['selectRacks-' + room.id]">
                {{ $t("accessDc.selectAllRacks") }}
              </button>
              <button class="cdlButton deselectRacks" @click="selectAllRacks(room, 1)"
                :id="['deselectRacks-' + room.id]">
                {{ $t("accessDc.deselectAllRacks") }}
              </button>
            </div>
          </div>
          <div class="contentRacks">
            <div v-for="(rack, indexRack) in room.racks" :key="indexRack" class="rack" :class="['room-' + room.id]"
              @click="setRack(room, rack)" :id="['rack-' + rack.id]">
              <p>Rack</p>
              <p>{{ rack.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mdl-grid" v-if="isMmr">
      <div class="mdl-cell mdl-cell--12-col">
        <hr />
        <p class="labelTwo" style="margin-bottom: 0">
          {{ $t("accessDc.uploadAttach") }}*
        </p>
        <span class="subTitleAttach">{{
      $t("accessDc.uploadAttachSubTitle")
    }}</span><br />
        <span class="error" v-if="this.v$.form.dc.mop.$invalid && this.v$.form.dc.mop.$dirty">
          {{ $t("accessDc.uploadMop") }}
        </span>
        <span class="error" v-else-if="errorAttachSize">
          {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
        </span>
        <span class="error" v-else-if="errorAttachType">
          {{ $t("shipping.attachFormat", { format: 'PDF' }) }}
        </span>
        <br />
        <span class="deleteOrder downloadMop">
          <a style="text-align: left" href="https://static-files.cdlan.net/assenzio/SOP_new_Method_of_Procedure.docx"
            download>{{ $t("accessDc.downloadMop") }}</a>
        </span>
        <br />
        <br />
      </div>
      <div class="mdl-cell mdl-cell--12-col listAttach listAttachPickUp">
        <div :class="{ hover: this.mopTmp.length == 0 || this.mopTmp[0] }" v-if="this.nAttach >= 1">
          <img src="@/assets/img/shipping/free_attach.png" alt="attach" v-if="this.mopTmp.length == 0" />
          <img src="@/assets/img/shipping/complete_attach.png" alt="attach" v-if="this.mopTmp[0]" />
          <p v-if="this.mopTmp[0]" class="fileName">
            {{ this.mopTmp[0].file }}
          </p>
          <input type="file" @change="onFileChanged($event, 0)" accept="application/pdf" capture class="fileInput" />
        </div>
      </div>
    </div>

    <hr />

    <!-- UTENTI -->
    <AccessDcUser :wizard-data="wizardData" @setUsers="setUsers" :errorNoUsersDad="errorNoUsersDad" :typeUsers="'dc'" />

    <hr />
    <!-- RIFERIMENTI -->
    <div>

      <h2>{{ $t('accessDc.titleAccessDcReferences') }}</h2>
      <p class="subTitle" v-if="false">{{ $t('accessDc.subTitleAccessDcReferences') }}</p>

      <RefTicket @update="updateRef" @validate="validate" />

    </div>
    <!-- FINE RIFERIMENTI -->

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t("checkCoverage.stepsButtonTwo") }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t("checkCoverage.stepsButtonOne") }}
      </div>
      <div class="cdlButtonSingle" @click="goNext(true)" v-if="wizardData.isCompleted">
        {{ $t("accessDc.goEnd") }}
      </div>
    </div>
    <p class="deleteOrder" v-if="!wizardData.isCompleted" @click="ctrlModalAccessDcRemove()">
      {{ $t("checkCoverage.stepsButtonThree") }}
    </p>
  </div>

  <ModalWarehouse
    :viewModal="modalWarehouse"
    @cancel="ctrlModalWarehouse"
  />
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import { useAccessDcStore } from "@/stores/AccessDcStore";
import RefTicket from '@/components/RefTicket.vue'
import AccessDcUser from '@/components/AccessDc/AccessDcUser.vue'
import ModalWarehouse from "@/components/AccessDc/ModalWarehouse.vue";



export default {
  name: "WhereAndWhat",
  components: {
    MyLoading,
    AccessDcUser,
    RefTicket,
    ModalWarehouse
  },
  setup() {
    const accessDcStore = useAccessDcStore();
    return {
      v$: useVuelidate(),
      accessDcStore
    };
  },
  validations() {
    return {
      form: {
        dc: {
          mop: { requiredIfFoo: requiredIf(this.isMmr) },
          noteKey: { requiredIfFoo: requiredIf(this.switchKeys) },
        }
      }
    };
  },
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        dc: {
          apparati: 'no',
          warehouse: 'no',
          manutenzione: false,
          keys: [],
          noteKey: null,
          racks: [],
          users: [],
          mop: null,
          ref: {
            ref_name: null,
            ref_surname: null,
            ref_phone: null,
            ref_email: null,
          },
        },
      },
      switchKeys: false,
      sizeScreen: true,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": " multipart/form-data",
      },
      rooms: [],
      errosKeys: false,
      errorRacks: false,
      viewLoaderRacks: false,
      dc_id: 0,
      nAttach: 1,
      errorAttachSize: false,
      errorAttachType: false,
      mopTmp: [],
      isMmr: false,
      maintenance: [],
      sizeAttach: 0,
      gammaID: 0,
      usersSelected: [],
      errorNoUsersDad: false,
      IsMobile: this.$IsMobile(),
      canGo: false,
      modalWarehouse: false,
      viewWarehouse: true,
      trakaRooms: []
    };
  },
  methods: {
    // DOVE E QUANDO
    setRoomKey(room) {
      if (document.getElementById("key-" + room.id)) {
        if (
          !document.getElementById("key-" + room.id).classList.contains("active")
        ) {
          // Aggiungo
          document.getElementById("key-" + room.id).classList.add("active");
          this.form.dc.keys.push(room);
        } else {
          // Tolgo
          document.getElementById("key-" + room.id).classList.remove("active");
          let rlmRemove = this.form.dc.keys.findIndex(item => { return item.id === room.id } );
          this.form.dc.keys.splice(rlmRemove, 1);

        }
      }
    },
    changeSwitchswitchApparati() {
      if (this.form.dc.apparati == "no") {
        this.form.dc.apparati = "si";
      } else {
        this.form.dc.apparati = "no";
      }
    },
    changeSwitchswitchWarehouse( modal = true ) {
      if (this.form.dc.warehouse == "no") {
        this.form.dc.warehouse = "si";
        if( modal ) {
          window.scrollTo(0, 0);
          this.modalWarehouse = true;
          document.getElementsByTagName("body")[0].classList.add("overflow");
        }
      } else {
        this.form.dc.warehouse = "no";
      }
    },
    ctrlModalWarehouse() {
      window.scrollTo(0, 0);
      this.modalWarehouse = !this.modalWarehouse;
      if (this.modalWarehouse) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    // changeSwitchswitchManutenzione() {
    //   if (this.form.manutenzione == "no") {
    //     this.form.manutenzione = "si";
    //   } else {
    //     this.form.manutenzione = "no";
    //   }
    // },
    changeSwitchswitchKeys() {
      this.switchKeys = !this.switchKeys;
      this.accessDcStore.switchKey = this.switchKeys

      if (!this.switchKeys) {
        this.accessDcStore.form.dc.keys = [];
      }
    },
    getRooms() {
      if (this.rooms.length == 0) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/cust-dcs/" +
            this.wizardData.form.dc.dc_id,
            { headers: this.he }
          )
          .then((response) => {
            this.rooms = response.data;
            this.getRacks();
          })
          .catch(() => {
            return self.$router.push({
              name: "AccessRequest" + self.$i18n.locale,
            });
          });
      }
    },
    getRacks() {
      this.rooms.forEach((room, i) => {
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/cust-racks/" +
            room.id,
            { headers: this.he }
          )
          .then((response) => {
            this.rooms[i].racks = response.data;
            this.viewLoaderRacks = true;
          });
      });

      if (this.wizardData.form.dc.racks.length > 0) {
        setTimeout(() => {
          this.wizardData.form.dc.racks.forEach((elm) => {
            elm.racks.forEach((rack) => {
              this.setRack(elm.room, rack);
            });
          });
        }, 4000);
      }
    },
    setTab(room_id) {
      let elmTab = document.getElementsByClassName("mdl-tabs__tab");
      let elmPanel = document.getElementsByClassName("mdl-tabs__panel");
      Object.entries(elmTab).forEach((elm) => {
        elm[1].classList.remove("is-active");
      });
      Object.entries(elmPanel).forEach((elm) => {
        elm[1].classList.remove("is-active");
      });
      document.getElementById("room-" + room_id).classList.add("is-active");
      document.getElementById("panel-" + room_id).classList.add("is-active");
    },
    setRack(room, rack) {
      this.errorRacks = false;
      let length = 0;
      if (this.form.dc.racks.length > 0) {
        let ctrl = false;
        this.form.dc.racks.forEach((elm, k) => {
          if (elm.room.id == room.id) {
            let ctrlRack = false;
            elm.racks.forEach((singleRack, i) => {
              if (singleRack.id == rack.id) {
                elm.racks.splice(i, 1);
                ctrlRack = true;
                document
                  .getElementById("rack-" + rack.id)
                  .classList.remove("active");
                length = elm.racks.length;
                if (room.name == "MMR A" || room.name == "MMR B") {
                  this.isMmr = false;
                  this.form.mop = null;
                  this.mopTmp = [];
                }
                if (length == 0) {
                  this.form.dc.racks.splice(k, 1);
                }
              }
            });
            if (!ctrlRack) {
              elm.racks.push(rack);
              if (room.name == "MMR A" || room.name == "MMR B") {
                this.isMmr = true;
              }
              document
                .getElementById("rack-" + rack.id)
                .classList.add("active");
              length = elm.racks.length;
            }
            ctrl = true;
          }
        });
        if (!ctrl) {
          if (room.name == "MMR A" || room.name == "MMR B") {
            this.isMmr = true;
          }
          document.getElementById("rack-" + rack.id).classList.add("active");
          this.form.dc.racks.push({
            room: room,
            racks: [rack],
          });
          length = 1;
        }
      } else {
        if (document.getElementById("rack-" + rack.id)) {
          if (room.name == "MMR A" || room.name == "MMR B") {
            this.isMmr = true;
          }
          document.getElementById("rack-" + rack.id).classList.add("active");
          this.form.dc.racks.push({
            room: room,
            racks: [rack],
          });
          length = 1;
        }
      }
      if (document.getElementById("badge-" + room.id)) {
        document.getElementById("badge-" + room.id).dataset.badge = length;
      }
    },
    selectAllRacks(room, ctrl = 0) {
      let elms = document.getElementsByClassName("room-" + room.id);
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });
      this.form.dc.racks.forEach((elm, i) => {
        if (elm.room.id == room.id) {
          this.form.dc.racks.splice(i, 1);
        }
      });
      if (ctrl == 0) {
        // Seleziona tutti
        document.getElementById("selectRacks-" + room.id).style.display =
          "none";
        document.getElementById("deselectRacks-" + room.id).style.display =
          "block";
        room.racks.forEach((rack) => {
          this.setRack(room, rack);
        });
      } else {
        // Deseleziona tutti
        document.getElementById("selectRacks-" + room.id).style.display =
          "block";
        document.getElementById("deselectRacks-" + room.id).style.display =
          "none";
        document.getElementById("badge-" + room.id).dataset.badge = 0;

        if (room.name == "MMR A" || room.name == "MMR B") {
          this.isMmr = false;
        }
      }
    },
    onFileChanged($event, index) {
      const target = $event.target;
      this.sizeAttach = 0;
      if (target && target.files) {
        this.errorAttachSize = false;
        this.errorAttachType = false;

        if (target.files[0].type != 'application/pdf') {
          this.errorAttachType = true;
          return;
        }

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        let data = {
          attachments: [],
        };

        data.attachments.push(target.files[0]);

        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/uploads/",
            data,
            { headers: this.he }
          )
          .then((response) => {
            this.form.dc.mop = [
              {
                zoo_id: response.data[0].zoho_id,
                filename: response.data[0].file,
              },
            ];
            this.mopTmp[index] = response.data[0];
          });
      }
    },
    changeSwitch() {
      this.switch = !this.switch;
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },

    //UTENTI
    setUsers(result) {
      this.usersSelected = result;
      localStorage.setItem('accessDc', JSON.stringify(result));
    },

    // RIFERIMENTI
    updateRef(stepData) {
      Object.assign(this.form.dc.ref, stepData);
    },
    validate(result) {
      this.canGo = result;
    },


    // CONDIVISIONE
    goBack() {
      this.$emit('goBack');
    },
    async goNext(goSummary = null) {
      this.errosKeys = false;
      this.errorRacks = false;
      this.errorNoUsersDad = false;

      let errors = 0;

      // this.errorNoUsers = false;
      // this.errorNoUsersBio = false;
      this.goSummary = goSummary;

      if (this.switchKeys) {
        // let elems = document.getElementsByClassName("singleRoomKeys");
        // Object.entries(elems).forEach((elm, i) => {
        //   if (elm[1].classList.contains("active")) {
        //     this.form.dc.keys.push(this.rooms[i]);
        //   }
        // });
        if (this.form.dc.keys.length == 0) {
          this.errosKeys = true;
          errors++;
        }
      }

      if (this.form.dc.racks.length == 0) {
        this.errorRacks = true;
        errors++;
      }

      if (this.usersSelected.length == 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.errorNoUsersDad = true;
        errors++;
      }

      // this.errorNoUsersDad = !this.errorNoUsersDad;

      if (!this.canGo) {
        errors++;
      }

      const result = await this.v$.$validate();
      if (result && errors == 0) {
        this.v$.$reset();
        let goSummary = this.goSummary;

        let accessDcStore = useAccessDcStore();
        Object.assign(accessDcStore.form.dc, this.form.dc);

        accessDcStore.form.dc.users = this.usersSelected;
        accessDcStore.form.coworking.users = this.usersSelected;
        // accessDcStore.form.parking.users = this.usersSelected;


        // this.form.users = this.usersSelected
        this.$emit('update');
        this.$emit('goNext', { goSummary });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    ctrlModalAccessDcRemove() {
      this.$emit('close');
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    searchTraka() {
      this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/private-rooms?dc_id=" + this.dc_id,
            { headers: this.he }
          )
          .then((response) => {
            this.trakaRooms = response.data;
          }).catch(function (err) {
            console.log(err);
          });
    }
  },
  activated() {

    // COSA
    if (this.rooms.length == 0) {
      this.getRooms();
    }

    window.addEventListener("resize", () => {
      var w = window.innerWidth;
      this.sizeScreen = true;
      if (w < 1600) {
        this.sizeScreen = false;
      }
    });

    this.gammaID = this.wizardData.gammaId;

    this.dc_id = this.wizardData.form.dc.dc_id;
    if (localStorage.getItem("cdlAccessDc")) {
      let ticket = JSON.parse(localStorage.getItem("cdlAccessDc"));
      if (ticket.accessDc.dc.mop && ticket.accessDc.dc.mop.length > 0) {
        this.form.mop = ticket.accessDc.dc.mop;
        this.mopTmp = ticket.accessDc.dc.mop;
        this.mopTmp[0].file = ticket.accessDc.dc.mop[0].filename;
      }
    }

    this.searchTraka();

    setTimeout(() => {
      if (this.wizardData.form.dc.keys.length > 0) {
        this.changeSwitchswitchKeys();
        if (document.getElementsByClassName("changeKeysOne")[0]) {
          document.getElementsByClassName("changeKeysOne")[0].checked = true;
        }
        if (document.getElementsByClassName("changeKeysTwo")[0]) {
          document.getElementsByClassName("changeKeysTwo")[0].checked = true;
        }
        this.form.dc.noteKey = this.wizardData.form.dc.noteKey;
        this.switchKeys = true;
        if (this.wizardData.isCompleted == true) {
          setTimeout(() => {
            this.wizardData.form.dc.keys.forEach((elm) => {
              this.setRoomKey(elm);
            });
          }, 800);
        }
      }
    }, 500);

    if (this.wizardData.form.dc.apparati == "si") {
      this.changeSwitchswitchApparati();
      if (document.getElementsByClassName("changeApparatiOne")[0]) {
        document.getElementsByClassName("changeApparatiOne")[0].checked = true;
      }
      if (document.getElementsByClassName("changeApparatiTwo")[0]) {
        document.getElementsByClassName("changeApparatiTwo")[0].checked = true;
      }
    }

    if (this.wizardData.form.dc.warehouse == "si") {
      this.changeSwitchswitchWarehouse( false );
      if (document.getElementsByClassName("changeWarehouseOne")[0]) {
        document.getElementsByClassName("changeWarehouseOne")[0].checked = true;
      }
      if (document.getElementsByClassName("changeWarehouseTwo")[0]) {
        document.getElementsByClassName("changeWarehouseTwo")[0].checked = true;
      }
    }

  },
  mounted() {
    if( this.wizardData.form.dc.dc_id == 4 ) {
      // Se è Roma nascondi
      this.viewWarehouse = false;
    }
  }
};
</script>
